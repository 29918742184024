
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace('')
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91_46_46_46404_932Lc1mYZZaMMeta } from "/app/src/pages/[...404].vue?macro=true";
import { default as _91id_936hzAl7LhbPMeta } from "/app/src/pages/accounts/[id].vue?macro=true";
import { default as indexeEvYT97ubbMeta } from "/app/src/pages/accounts/index.vue?macro=true";
import { default as accountskqONWh2WVcMeta } from "/app/src/pages/charts/accounts.vue?macro=true";
import { default as contractsIJUwWbqTTkMeta } from "/app/src/pages/charts/contracts.vue?macro=true";
import { default as difficultyUFXRqW1062Meta } from "/app/src/pages/charts/difficulty.vue?macro=true";
import { default as hashrateRURjYPThhAMeta } from "/app/src/pages/charts/hashrate.vue?macro=true";
import { default as keyblocksR2vr6PTTSXMeta } from "/app/src/pages/charts/keyblocks.vue?macro=true";
import { default as namesScQpSSxexHMeta } from "/app/src/pages/charts/names.vue?macro=true";
import { default as transactionsyNkoYCyKjuMeta } from "/app/src/pages/charts/transactions.vue?macro=true";
import { default as indexI1kEJARmbrMeta } from "/app/src/pages/contract-verification/index.vue?macro=true";
import { default as result9mIJRbjIEFMeta } from "/app/src/pages/contract-verification/result.vue?macro=true";
import { default as _91id_93elMVxtgor5Meta } from "/app/src/pages/contracts/[id].vue?macro=true";
import { default as index2aVVPC7uKGMeta } from "/app/src/pages/contracts/index.vue?macro=true";
import { default as indexMUcQhzWwqyMeta } from "/app/src/pages/dex-trades/index.vue?macro=true";
import { default as indexdPwi6hPdMfMeta } from "/app/src/pages/index.vue?macro=true";
import { default as _91id_93RlywX5m6kGMeta } from "/app/src/pages/keyblocks/[id].vue?macro=true";
import { default as indexx0Zm1MbpW1Meta } from "/app/src/pages/keyblocks/index.vue?macro=true";
import { default as _91id_93oDftGkctN5Meta } from "/app/src/pages/microblocks/[id].vue?macro=true";
import { default as _91name_93Az8xnVHEWiMeta } from "/app/src/pages/names/[name].vue?macro=true";
import { default as indexUHm9d3A4hbMeta } from "/app/src/pages/names/index.vue?macro=true";
import { default as _91id_93e9UxJ2urYZMeta } from "/app/src/pages/nfts/[id].vue?macro=true";
import { default as indexYsxhjvyZhxMeta } from "/app/src/pages/nfts/index.vue?macro=true";
import { default as index4HA5dCFoYYMeta } from "/app/src/pages/nodes/index.vue?macro=true";
import { default as _91id_93KFAsu9r6pqMeta } from "/app/src/pages/oracles/[id].vue?macro=true";
import { default as indexpP3o2LVDnvMeta } from "/app/src/pages/oracles/index.vue?macro=true";
import { default as index0anYxncBX1Meta } from "/app/src/pages/privacy-policy/index.vue?macro=true";
import { default as _91id_93WJHjJsQqEMMeta } from "/app/src/pages/search/[id].vue?macro=true";
import { default as _91id_93JWklyDbrUPMeta } from "/app/src/pages/state-channels/[id].vue?macro=true";
import { default as indexiye6WtGMc0Meta } from "/app/src/pages/state-channels/index.vue?macro=true";
import { default as indexPw5gPWdi5tMeta } from "/app/src/pages/terms-of-service/index.vue?macro=true";
import { default as _91id_933PTt44KV7RMeta } from "/app/src/pages/tokens/[id].vue?macro=true";
import { default as ae3wLDb4pMJVMeta } from "/app/src/pages/tokens/ae.vue?macro=true";
import { default as indexVyPT5FYCTlMeta } from "/app/src/pages/tokens/index.vue?macro=true";
import { default as _91id_93GUVfHYfa52Meta } from "/app/src/pages/transactions/[id].vue?macro=true";
import { default as indexMjjHIvXpDVMeta } from "/app/src/pages/transactions/index.vue?macro=true";
import { default as indexhjcWzCs0NuMeta } from "/app/src/pages/wallet/index.vue?macro=true";
export default [
  {
    name: "404",
    path: "/:404(.*)*",
    component: () => import("/app/src/pages/[...404].vue")
  },
  {
    name: "accounts-id",
    path: "/accounts/:id()",
    component: () => import("/app/src/pages/accounts/[id].vue")
  },
  {
    name: "accounts",
    path: "/accounts",
    component: () => import("/app/src/pages/accounts/index.vue")
  },
  {
    name: "charts-accounts",
    path: "/charts/accounts",
    component: () => import("/app/src/pages/charts/accounts.vue")
  },
  {
    name: "charts-contracts",
    path: "/charts/contracts",
    component: () => import("/app/src/pages/charts/contracts.vue")
  },
  {
    name: "charts-difficulty",
    path: "/charts/difficulty",
    component: () => import("/app/src/pages/charts/difficulty.vue")
  },
  {
    name: "charts-hashrate",
    path: "/charts/hashrate",
    component: () => import("/app/src/pages/charts/hashrate.vue")
  },
  {
    name: "charts-keyblocks",
    path: "/charts/keyblocks",
    component: () => import("/app/src/pages/charts/keyblocks.vue")
  },
  {
    name: "charts-names",
    path: "/charts/names",
    component: () => import("/app/src/pages/charts/names.vue")
  },
  {
    name: "charts-transactions",
    path: "/charts/transactions",
    component: () => import("/app/src/pages/charts/transactions.vue")
  },
  {
    name: "contract-verification",
    path: "/contract-verification",
    component: () => import("/app/src/pages/contract-verification/index.vue")
  },
  {
    name: "contract-verification-result",
    path: "/contract-verification/result",
    component: () => import("/app/src/pages/contract-verification/result.vue")
  },
  {
    name: "contracts-id",
    path: "/contracts/:id()",
    component: () => import("/app/src/pages/contracts/[id].vue")
  },
  {
    name: "contracts",
    path: "/contracts",
    component: () => import("/app/src/pages/contracts/index.vue")
  },
  {
    name: "dex-trades",
    path: "/dex-trades",
    component: () => import("/app/src/pages/dex-trades/index.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexdPwi6hPdMfMeta || {},
    component: () => import("/app/src/pages/index.vue")
  },
  {
    name: "keyblocks-id",
    path: "/keyblocks/:id()",
    component: () => import("/app/src/pages/keyblocks/[id].vue")
  },
  {
    name: "keyblocks",
    path: "/keyblocks",
    component: () => import("/app/src/pages/keyblocks/index.vue")
  },
  {
    name: "microblocks-id",
    path: "/microblocks/:id()",
    component: () => import("/app/src/pages/microblocks/[id].vue")
  },
  {
    name: "names-name",
    path: "/names/:name()",
    component: () => import("/app/src/pages/names/[name].vue")
  },
  {
    name: "names",
    path: "/names",
    component: () => import("/app/src/pages/names/index.vue")
  },
  {
    name: "nfts-id",
    path: "/nfts/:id()",
    component: () => import("/app/src/pages/nfts/[id].vue")
  },
  {
    name: "nfts",
    path: "/nfts",
    component: () => import("/app/src/pages/nfts/index.vue")
  },
  {
    name: "nodes",
    path: "/nodes",
    component: () => import("/app/src/pages/nodes/index.vue")
  },
  {
    name: "oracles-id",
    path: "/oracles/:id()",
    component: () => import("/app/src/pages/oracles/[id].vue")
  },
  {
    name: "oracles",
    path: "/oracles",
    component: () => import("/app/src/pages/oracles/index.vue")
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    component: () => import("/app/src/pages/privacy-policy/index.vue")
  },
  {
    name: "search-id",
    path: "/search/:id()",
    component: () => import("/app/src/pages/search/[id].vue")
  },
  {
    name: "state-channels-id",
    path: "/state-channels/:id()",
    component: () => import("/app/src/pages/state-channels/[id].vue")
  },
  {
    name: "state-channels",
    path: "/state-channels",
    component: () => import("/app/src/pages/state-channels/index.vue")
  },
  {
    name: "terms-of-service",
    path: "/terms-of-service",
    component: () => import("/app/src/pages/terms-of-service/index.vue")
  },
  {
    name: "tokens-id",
    path: "/tokens/:id()",
    component: () => import("/app/src/pages/tokens/[id].vue")
  },
  {
    name: "tokens-ae",
    path: "/tokens/ae",
    component: () => import("/app/src/pages/tokens/ae.vue")
  },
  {
    name: "tokens",
    path: "/tokens",
    component: () => import("/app/src/pages/tokens/index.vue")
  },
  {
    name: "transactions-id",
    path: "/transactions/:id()",
    component: () => import("/app/src/pages/transactions/[id].vue")
  },
  {
    name: "transactions",
    path: "/transactions",
    component: () => import("/app/src/pages/transactions/index.vue")
  },
  {
    name: "wallet",
    path: "/wallet",
    component: () => import("/app/src/pages/wallet/index.vue")
  }
]